.project_table {
	.stages {
		width: 200px;
	}

	table {
		td {
			img {
				border-radius: 50%;
			}

			.table_stages {
				background-color: aliceblue;
			}
		}
	}
}

.image_icon {
	width: 50px;
	height: 50px;
}

.image {
	// max-width: 100%;
	// max-height: 100%;
	transition: transform 0.2s ease;
	
	&:hover {
		transform: scale(1.05);
	}
}